<template>
  <div>
    <MasterHeader />
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <div class="container container-wide">

      <div class="widget-filter">

        <ul>
          <li @click="changeViewStatus('all')" :class="viewStatus === 'all' ? 'active' : ''">
            All
          </li>
           <li @click="changeViewStatus('draft')" :class="viewStatus === 'draft' ? 'active' : ''">
            Draft
          </li>
           <li @click="changeViewStatus('review')" :class="viewStatus === 'review' ? 'active' : ''">
            In Review
          </li>
          <li @click="changeViewStatus('published')" :class="viewStatus === 'published' ? 'active' : ''">
            Published
          </li>
          <li @click="changeViewStatus('collaborating')" :class="viewStatus === 'collaborating' ? 'active' : ''">
            Collaborating
          </li>
         
          <li @click="changeViewStatus('deleted')" :class="viewStatus === 'deleted' ? 'active' : ''">
            Deleted
          </li>
        </ul>

      </div>




      <div class="row">

        <div class="col-12">
          <div class="widget">
            <!-- <div class="widget-splash">
                <img>
            </div> -->
            <div class="widget-header sticky">
                <h4><b>Thesis Topics</b></h4>
                <div class="widget-navigation">
                    <router-link to="/collaboration/new" class="widget-nav"><i class="fas fa-plus"></i>Create Thesis Topic</router-link>
                </div>
            </div>
            <div class="widget-body">
              <template v-if="collaborationsByUser.length > 0">
                <router-link :to="'/collaborations/' + collab.id" class="erow link row d-flex" v-for="(collab, index) in collaborationsByUser" :key="index">
                    <div class="col-1 align-self-center">
                        <div class="align-self-center">
                            <i class="fas fa-hands-helping" ></i>
                        </div>
                    </div>
                    <div class="col-6 align-self-center"> 
                        <div>Thesis title</div>
                        <div class="headline">
                          {{ collab.collaboration.title }}
                        </div>
                    </div>
                    <div class="col-2 align-self-center">
                        <div>Status</div>
                        <div class="headline">{{ collab.collaboration.status.replace('review','In review') }}</div>
                    </div>
                    <div class="col-2 align-self-center">
                        <div>Created</div>
                        <div class="headline">{{ moment(collab.collaboration.createdDate.seconds).format("DD-MM-Y") }}</div>
                    </div>

                    <div class="col-1 align-self-center text-right">
                        <!-- <router-link :to="'/collaboration/edit/' + collab.id" class="color-grey" v-if="collab.collaboration.status !== 'collaborating'"><i class="fas fa-pen"></i></router-link> -->
                        <!-- <router-link :to="'/collaborations/' + collab.id" class="color-first ml-3"> -->
                        
                        <span class="btn btn-sm btn-primary">View</span>
                        <!-- <i class="fas fa-eye"></i> -->
                        <!-- </router-link> -->
                    </div>
                </router-link>
                </template>
                <div class="erow row d-flex" v-else>
                      <div class="col-12 align-self-center text-center">
                          <div class="align-self-center">
                            <div class="headline"><i class="fas fa-hands-helping" ></i> No results in '{{ viewStatus }}'</div>
                          </div>
                      </div>
                  </div>
 

            </div>
            <!-- <div class="widget-footer">
                [ CTA LINK TO SUBJECT CONTENT ]
            </div> -->
        </div>


        <!-- STUDENT COLLABORATION INVITES -->
        <div v-if="collaborationInvites.length > 0">
            <div class="widget">

                <div class="widget-header">
                    <h4>Invites</h4>
                    <div class="widget-navigation">
                        <!-- <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-link"></i>My collaborations</router-link> -->
                    </div>
                </div>
                <div class="widget-body">
                    <router-link :to="'/collaborations/' + invite.invites.collaborationId" v-for="(invite, index) in collaborationInvites" :key="index"  class="erow row link d-flex">
                        <div class="col-1 align-self-center">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="">From</div>
                            <div class="headline">{{ invite.invites.sender }}</div>
                        </div>
                        <div class="col-6 align-self-center">
                            <div class="">Thesis title</div>
                            <div class="headline">{{ invite.invites.collaborationTitle }}</div>
                        </div>
                        <div class="col-1 align-self-center text-right">
                          <span class="btn btn-link">View</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        </div>

      </div>
    </div>
  </div> 
</template>

<script>
import {mapState} from 'vuex'
import { db } from '../../firebase'
import moment from 'moment'
import MasterHeader from '@/components/MasterHeader.vue'
import SignUpModal from '../../components/signUpModal.vue'

export default {

  data() {
      return {
        viewStatus: 'all',
      }
  },
  components: {
      MasterHeader,
    SignUpModal,
  },


  computed: {
    ...mapState(['user']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    collaborationsByUser: function() {
      const collection = this.$store.state.collaboration.usersCollaborations;
      var result = [];
      var status = this.viewStatus;
      if(status === "all") {
        result = collection;
      } else {
        result = collection.filter(item => {
          return item.collaboration.status === status;
        });
      }


      return result;
    },
    collaborationInvites: function() {
        return this.$store.state.collaboration.collaborationInvites;
    },
    collaborations: function () {
      return this.$store.state.collaboration.usersCollaborations;
    }
  },
  created() {
      this.$store.dispatch('getCollaborationByUserId', this.user);
      this.$store.dispatch('getCollaborationInvitesByUserId');
  },
  methods: {
    collaborationMembersFromIds(membersArray) {
      const result = [];

      membersArray.forEach(function(memberId, index) {

        var ref = db.collection("users").doc(memberId);
        ref.get()
        .then(function(querySnapshot) {
          const collection = querySnapshot.data();
          result.push({ "id":querySnapshot.id, "members": querySnapshot.data()});
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });

      });

      return result;
    },

    changeViewStatus(status) {
      this.viewStatus = status;
    },

    moment: function (seconds) {
      return moment.unix(seconds);
    },
    cutText: function(text, characters) {
        return text.length > characters ? text.substring(0, characters) + '...' : text;
    } 
  }
}
</script>

<style>

</style>